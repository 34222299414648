<template>
  <div>
    <header class="rs-report__secondary-title">
      <EditorSimpleText v-if="isEditing" :value="value" @input="emitUpdate" />
      <template v-else>
        <ReportContentOutput :value="value" />
      </template>
    </header>
    <v-divider
      v-if="!isMobile"
      :class="[
        pdfType ? 'mb-4 mt-2' : 'mb-8 mt-4',
        pdfType === 'print' ? 'grey' : 'white',
      ]"
      >&nbsp;</v-divider
    >
  </div>
</template>

<script>
import EditorSimpleText from "@/components/editors/EditorSimpleText";
import ReportContentOutput from "@/components/ReportContentOutput.vue";

export default {
  components: {
    EditorSimpleText,
    ReportContentOutput,
  },
  props: {
    value: {
      type: String,
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
    },
    isMobile: Boolean,
    pdfType: String,
  },
  methods: {
    emitUpdate(e) {
      this.$emit("update:value", e);
    },
  },
};
</script>
